import { createContext, useState, useContext } from "react";

const AppStateStore = createContext();
export const useAppStateStore = () => useContext(AppStateStore);


export const initialState = {
  status     : 'home',
  endpoint   : 'https://n3tdnqfo54.execute-api.us-east-1.amazonaws.com/1/'
};

export default function AppStateProvider({children}) {
  const [appState, setAppState] = useState(initialState);
  
  return (
    <AppStateStore.Provider value={{ appState, setAppState }}>
      {children}
    </AppStateStore.Provider>
  );
}