import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useAppStateStore } from "./AppStateProvider";
import { useEffect } from 'react';
import Home from './Home';
import Donate from './Donate';


interface Props {
  children: React.ReactNode
}

function Page() {
  const { appState } = useAppStateStore();

  if ('donate' === appState.status) {
    return (<Donate />);    
  }
  else {
    return (<Home />);  
  }
}

const Links = ['Home', 'Donate'];

const NavLink = (props: Props) => {
  const { children } = props;
  
  const returnLink = () => {
    if ('Donate' === children) {
      return '#donate';
    }
    else {
      return '#home';
    }
  }  
  
  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.500', 'gray.800'),
      }}
      color={'gray.100'}
      href={returnLink()}>
      {children}
    </Box>
  )
}

export default function Navigation() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { appState, setAppState } = useAppStateStore();

  useEffect(() => {
    window.addEventListener("hashchange", () => {      
      const hash = window.location.hash.replace('#', '') || 'home';
      setAppState({ ...appState, status : hash, });    
    });
    
    const hash = window.location.hash.replace('#', '') || 'home';
    setAppState({ ...appState, status : hash, });
  }, []); 
  
  return (
    <>
      <Box bg={useColorModeValue('gray.900', 'gray.100')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <Box><Image src='/sacredfire-logo-white.png' style={{height: 60, width: 117}} alt="A stylized flame next to the words Sacred Fire UU" /></Box>
          </HStack>
          <Flex alignItems={'center'}>
            <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </HStack>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
      
      <Page />
    </>
  )
}