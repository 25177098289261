import { Box, Text, Link, Card, CardHeader, Stack, Heading, CardBody, StackDivider, Image } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useAppStateStore } from "./AppStateProvider";


function Home() {
  const { appState, setAppState } = useAppStateStore();
  
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });  
  const session_id = params.session_id;
  
  useEffect(() => {    
    if (session_id) {
      fetch(appState.endpoint + 'session?id=' + session_id, {
        method : "GET"
      })
      .then((res) => {
        res.json().then((data) => {
          setAppState({ ...appState, payment : data.payment, });
        });
      })
      .catch((err) => {
        console.error(err);
        alert('error');
      });     
    }      
  }, []);

  if (appState.payment) {
    if ("paid" === appState.payment.payment_status) {
      return (
        <Card width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Thanks!</Heading>
          </CardHeader>
        
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
              <Text pt='2' fontSize='md'>Your donation of ${parseFloat(appState.payment.amount_total / 100).toFixed(2)} was successful.</Text>              
            </Stack>
          </CardBody>
        </Card>
      );    
    }
    else {
      return (
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Thanks!</Heading>
          </CardHeader>
        
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
              <Text pt='2' fontSize='md'>
              There appears to have been a problem processing your payment. Please try 
              again or contact us. 
              </Text>              
            </Stack>
          </CardBody>
        </Card>
      );        
    }
  }
  else {
    return (
      <>
        <Box 
          h='600' 
          backgroundImage="url('/dawn.jpg')"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
        >
        
          <Text
            fontSize='lg'
            align='center'
            color='#FFFFFF'
            pt='200'
          > <em>Welcome to</em> </Text>
          <Text
            fontSize='5xl'
            align='center'
            color='#FFFFFF'
          ><strong>SACRED FIRE UNITARIAN</strong></Text>
          <Text
            fontSize='5xl'
            align='center'
            color='#FFFFFF'
          ><strong>UNIVERSALIST</strong></Text>
        
        </Box>
  
        <Box pt={4} pl={[2, 2, 5, 20]} pr={[2, 2, 5, 20]} background='#000000' minHeight={400}>
        
          <Image src='/fractal.jpg' style={{height: 300, width: 'auto', float: 'left'}} alt="decorative image" />
        
          <Text color="white" >
            The ministry of Sacred Fire Unitarian Universalist plants and supports covenanted
            communities, and helps our congregations to be more effective in their racial- and
            social justice impacts.
          </Text>
  
          <Text mt={5} color="white">
            We offer trainings to congregations, and to ministers through the UU Ministers
            Association. In addition, we have our own social justice focuses, including
            support of immigrant and refugee&rsquo;d people, food sovereignty, and our Solidarity
            Network.
          </Text>
  
          <Text mt={5} color="white">
            Most recently, we have launched the <Link color='blue.600' href='https://www.ministerbookexchange.org' isExternal>
            Minister Book Exchange</Link>, wherein ministers and
            others donate books as charitable contributions, and we distribute them to
            divinity school students and others at below-market cost. Part of this project is
            also to offer &ldquo;Banned Book Bundles&rdquo; to families with limited access.
          </Text>
  
          <Text mt={5} color="white">
            Please consider booking us for trainings, joining our UUMA continuing education
            sessions, and giving monetary donations to support our work and to subsidize the
            cost of the books we offer.
          </Text>
  
        </Box>
      </>   
    );  
  }

}

export default Home;