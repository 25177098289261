import React from 'react';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';
import Navigation from './Navigation';

import AppStateProvider from "./AppStateProvider";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AppStateProvider>
        <Navigation />
      </AppStateProvider>
    </ChakraProvider>
  );
}

export default App;
