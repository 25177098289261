import { useAppStateStore } from "./AppStateProvider";
import { Box, Text, Card, CardBody, CardHeader, Heading, 
  Stack, StackDivider, Input, Button } from '@chakra-ui/react';
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_live_2izIX9dEBxAT8EVtvNgMmTDk');

function Donate() {
  const { appState, setAppState } = useAppStateStore();

  const updateField = (evt) => {
    const errors = { amtInvalid : false, };
    setAppState({ ...appState, ...errors, [evt.target.name]: evt.target.value, });
  };

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });  
    
  const goPayment = () => {
    const amount = parseFloat(appState.donationAmt.replace(/[^.\d]/g, ''));
    if (amount > 0) {
      setAppState({ ...appState, loading: true, });
      fetch(appState.endpoint + 'session', {
        method : 'POST',
        body   : JSON.stringify({amount : parseInt(appState.donationAmt * 100)})
      })
      .then((res) => {
        res.json().then((data) => {
          if ('OK' === data.status) {
            setAppState({ 
              ...appState, 
              loading : false, 
              session : data['session'],
              donationAmt : amount,
              amtInvalid  : false, 
            });
          }
          else {
            setAppState({ ...appState, loading: false, });
            alert('Error saving data. Please try again.');
          }
        })
      })
      .catch((err) => {
        console.error(err);
        setAppState({ ...appState, loading: false, });
        alert('error');
      });    
    }
    else {
      setAppState({ ...appState, amtInvalid: true, });
    }
  };
    
  if (appState.session) {
    const fetchClientSecret = () => {
      return appState.session.client_secret;
    };

    const options = {fetchClientSecret};
    
    return (
      <Box
        display="flex"
        alignItems='top'
        justifyContent='center'
        width='100%'
        height='100%'
        pT='50'
        mT='0'
        minH='1000'
        bgGradient="linear(to-b, white, #cccccc)"
      >
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Donation Processing Details</Heading>
          </CardHeader>
        
          <CardBody>
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={options}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>          
          </CardBody>
        </Card>
      </Box>
    )
  }
  else {
    return (
      <Box
        display="flex"
        alignItems='top'
        justifyContent='center'
        width='100%'
        height='100%'
        pT='50'
        mT='0'
        minH='1000'
        bgGradient="linear(to-b, white, #cccccc)"
      >
      
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Tax Deductable Monetary Donation</Heading>
          </CardHeader>
        
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
              <Text pt='2' fontSize='md'>Amount of Donation:</Text>
              
              <Input variant='outline' placeholder='$ ' name='donationAmt' value={(appState.donationAmt && !isNaN(appState.donationAmt)) ? appState.donationAmt : ''} onChange={updateField} isInvalid={appState.amtInvalid} />            
              
              <Button colorScheme='blue' size='lg' onClick={goPayment} isLoading={appState.loading}>Continue</Button>
            </Stack>
          </CardBody>
        </Card>
  
      </Box>  
    );  
  }

}

export default Donate;